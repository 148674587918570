import store from "@/store";

/**
 * 检查是否有路由权限
 * @param {Object} route 路由
 */
export function checkRoute(route) {
  let result = true;

  if (route.meta && route.meta.permission && route.meta.permission) {
    result = false;
    if (store.getters.permissions.indexOf("*") > -1) {
      result = true;
    } else {
      for (const permission of store.getters.permissions) {
        if (permission.name && permission.name === route.meta.permission) {
          result = true;
          break;
        }
      }
    }
  }

  return result;
}

/**
 * 所有的模型 slug 数组
 */
function allModels() {
  const models = [];

  store.getters.allModels.forEach(element => {
    models.push(element.slug);
  });

  return models;
}

/**
 * 模型不需要权限
 * @param {*} model 模型
 */
function modelNeedNotPermission(model) {
  return allModels().indexOf(model) === -1;
}

/**
 * 检查模型权限
 * @param {String} model 模型
 * @param {String} action 操作
 * @param {String} actionType 操作权限对应类型
 */
function checkModelPermission(model, action, actionType) {
  // 模型权限不需要验证
  let result = modelNeedNotPermission();

  if (!result) {
    for (const element of store.getters.models) {
      const elemetnFit =
        element === "*" || (element.rules && element.rules === "*");
      const modelFit = element.model && element.model === model;
      result =
        elemetnFit ||
        (modelFit &&
          (element.rules[action] &&
            (actionType === "bool" ||
              (actionType === "length" && element.rules[action].length > 0) ||
              element.rules[action].indexOf(actionType) > 0)));

      if (result) {
        break;
      }
    }
  }

  return result;
}

export function checkShow(model) {
  return checkModelPermission(model, "show", "length");
}

export function checkExport(model) {
  return checkModelPermission(model, "export", "length");
}

export function checkAdd(model) {
  return checkModelPermission(model, "add", "bool");
}

export function checkEdit(model, type) {
  return checkModelPermission(model, "edit", type);
}

export function checkDelete(model, type) {
  return checkModelPermission(model, "delete", type);
}

function checkModelField(model, field, type = "show") {
  var result = true;

  // 模型不需要权限
  if (
    modelNeedNotPermission(model) ||
    store.getters.models.indexOf("*") !== -1
  ) {
    return result;
  }

  // 如果设置了对应模型的对应字段
  for (const element of store.getters.allModels) {
    if (element.slug === model) {
      for (const eField of element.fields) {
        if (eField.field === field) {
          result = false;
          break;
        }
      }
      break;
    }
  }

  if (!result) {
    for (const element of store.getters.models) {
      if (element.model === model) {
        if (Array.isArray(element.rules) && element.rules.indexOf("*") !== -1) {
          result = true;
        } else {
          result = element.rules.fields[field][type];
        }
        break;
      }
    }
  }

  return result;
}

export function checkModelFieldShow(model, field) {
  return checkModelField(model, field, "show");
}

export function checkModelFieldEdit(model, field) {
  return checkModelField(model, field, "edit");
}

export function checkMerchantId() {
  if (store.getters.name == "超级管理员") {
    return true;
  } else {
    return false;
  }
}

export function checkRoleLevel(role) {
  switch (role) {
    // 主管及以上
    case "supervisor":
      return store.getters.role_level <= 3;
    // 部员
    case "member":
      return store.getters.role_level === 4;
    default:
      return false;
  }
}

export function checkAdminWhite() {
  return store.getters.adminWhite;
}
