/**
 * @Author: freebird
 * @Date: 2020-05-27 20:00:00
 * @Last Modified by: freebird
 * @Last Modified time: 2020-07-23 14:57:27
 * 
 * 具有工具函数：
 *   1、提示信息
 *   2、确认操作弹框
 */

import { Message, MessageBox } from "element-ui";

/**
 * 提示信息
 * @param {String} text 消息框内容
 * @param {String} [type = 'info'] 消息框类型
 * type:
 *   success-成功|绿色
 *   warning-警告|橘色
 *   error-错误|红色
 *   info-消息|灰色
 * @returns { Message } element-ui消息框
 */
export function messages(text, type) {
    let params = {
        message: text,
        type: 'info',
        duration: 3 * 1000,
        showClose: true,
        center: false,
        dangerouslyUseHTMLString: false
    }
    if (type === 'success' || type === 'warning' || type === 'error' || type === 'info') {
        params.type = type
    }
    return Message(params);
}


/**
 * 确认操作弹框
 * @param {object} 传入配置参数对象
 * @param {String} params.text 确认操作框消息内容
 * @param {String} [params.type='info'] 确认操作框类型
 * type:
 *   success-成功|绿色
 *   warning-警告|橘色
 *   error-错误|红色
 *   info-消息|灰色
 * @param {Function} params.allowFun 确认回调函数
 * @param {Function} [params.failFun] 取消回调函数
 * @returns { MessageBox } element-ui确认操作框
 */
export function messagesBox(params) {
    if (params.type !== 'success' && params.type !== 'warning' && params.type !== 'error' && params.type !== 'info') {
        params.type = 'info'
    }
    return MessageBox.confirm(params.text ? params.text : '是否进行此操作？', "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: params.type
    })
        .then(() => { params.allowFun ? params.allowFun() : undefined })
        .catch(() => { params.failFun ? params.failFun() : undefined })
}
