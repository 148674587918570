import request from '@/utils/request';

/**
 * 列表
 * @param {object} query
 */
export function fetchList(query) {
  return request.get('v1/admin', { params: query });
}

/**
 * 详情
 * @param {Integer} id
 */
export function fetchDetail(id) {
  return request.get('v1/admin/' + id);
}

/**
 * 编辑
 * @param {Object} params
 */
export function modify(params) {
  let url = 'v1/admin';
  if (params.id) {
    url += '/' + params.id;
  }
  return request.post(url, params);
}

/**
 * 删除
 * @param {Integer} id
 */
export function deleteModel(id) {
  return request.delete('v1/admin/' + id);
}

/**
 * 恢复
 * @param {Integer} id
 */
export function recover(id) {
  return request.post('v1/admin/' + id + '/recover');
}

export function adminSelect(query) {
  return request.get('v1/api/admin', { params: query });
}

export function adminCoverSelect(query) {
  return request.get('v1/api/adminNew', { params: query });
}
// 无权限展示所有用户
export function adminSelectAll(query) {
  return request.get('v1/api/allAdmin', { params: query });
}

export function tree(query) {
  return request.get('v1/api/admin/tree', { params: query });
}

// Get DingDing QR Code
export function getQRcode(query) {
  return request.get('v1/getRedirectUri', { params: query });
}

// Binding DingDing Account
export function bindDDing(query) {
  return request.post('v1/admin/bindDingToUsername', query);
}

// 绑定成功的上报
export function reportDDing(query) {
  return request.post('v1/user/getPhone', query);
}
