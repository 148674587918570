<template>
  <el-select
    ref="select"
    v-lazy-load="lazyLoadFunc"
    :remote-method="optionSelect"
    :remote="remote"
    :filterable="filterable"
    :clearable="clearable"
    :value="keyObj ? objValue : value"
    :placeholder="placeholder"
    :loading="loading"
    :disabled="!!disabled"
    :multiple="multiple"
    :collapse-tags="collapseTags"
    :size="size"
    :style="width ? `width:${width}px` : ''"
    :value-key="isValueOption ? optionValue : undefined"
    :reserve-keyword="reserveKeyword"
    @input="input"
    @change="change"
    @blur="$emit('blur')"
    @focus="$emit('focus')"
    @clear="handleClear"
  >
    <el-checkbox
      v-if="checkAll"
      v-model="checkAllValue"
      :indeterminate="isIndeterminate"
      class="checkall"
      @change="handleCheckAllChange"
    >
      全选
    </el-checkbox>

    <!-- 单列缝合显示 -->
    <template v-if="!type || type === 'stitching'">
      <el-option
        v-for="(item, index) in activeOptions || activeList"
        :key="`${getValue(item)}_${index}`"
        :label="getLabel(item)"
        :value="getValue(item)"
        @click.native="selectOptionClick(item)"
      />
      <el-option
        v-for="(item, index) in unableOptions || unableList"
        :key="`${getValue(item)}_${index}`"
        :label="getLabel(item)"
        :value="getValue(item)"
        disabled
      />
    </template>

    <!-- 双列栅栏显示 -->
    <template v-if="type === 'double-fence'">
      <el-option
        v-for="(item, index) in activeOptions || activeList"
        :key="`${getValue(item)}_${index}`"
        :value="getValue(item)"
        :label="fenceLabel ? getFenceLabel(item) : item[optionLabel[0]]"
        @click.native="selectOptionClick(item)"
      >
        <span style="float: left">{{ item[optionLabel[0]] }}</span>
        &nbsp;&nbsp;
        <span style="float: right">{{ item[optionLabel[1]] }}</span>
      </el-option>
      <el-option-group v-if="!!(unableOptions || unableList).length">
        <HR />
        <el-option disabled style="color: black" value="unable-info">
          <label style="float: left">{{ unableInfo[0] }}</label>
          <label style="float: right">{{ unableInfo[1] }}</label>
        </el-option>
        <el-option
          v-for="(item, index) in unableOptions || unableList"
          :key="`${getValue(item)}_${index}`"
          :value="getValue(item)"
          disabled
        >
          <span style="float: left">{{ item[optionLabel[0]] }}</span>
          &nbsp;&nbsp;
          <span style="float: right">{{
            unableReason ? item[unableReason] : item[optionLabel[1]]
          }}</span>
        </el-option>
      </el-option-group>
    </template>

    <el-option v-if="isNull" :value="Math.random()" disabled label="无数据" />

    <div
      v-if="lazyLoad"
      v-show="lazyLoading || !canLoad"
      class="loading"
      align="center"
    >
      {{ canLoad ? "加载中" : "再拉也没有数据了" }}
    </div>
  </el-select>
</template>

<script>
export default {
  name: 'SearchSelect',
  directives: {
    'lazy-load': {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          if (this.scrollHeight - this.scrollTop <= this.clientHeight) {
            binding.value();
          }
        });
      },
    },
  },
  props: {
    // 作用：用于选项显示；变量：选项显示的字段或字段数组
    optionLabel: {
      type: [Array, String],
      default: 'label',
    },
    // 作用：双栏显示回调时显示的选项label；变量：选项显示的字段或字段数组
    fenceLabel: {
      type: [Array, String],
    },
    // 作用：指定选项的value绑定的选项的字段；变量：value绑定的选项的字段
    optionValue: {
      type: [Array, String],
      default: 'value',
    },
    // 作用：指定不能选择的原因的显示的字段；变量：原因绑定的选项的字段
    unableReason: {
      type: String,
    },
    // 搜索的函数，函数返回值为Promise
    searchFunc: {
      type: Function,
      default: () => {
        return new Promise(() => []);
      },
    },
    // 接口返回的列表非list时指定key值
    searchKey: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    // el-select组件所绑定的变量
    value: {
      type: [String, Number, Array],
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    size: {
      type: String,
      default: 'mini',
    },
    disabled: {
      type: [Boolean, Number, String],
      default: false,
    },
    // 监听的对象
    watchKey: {
      type: [String, Number],
    },
    // 当watchKey为空时也触发搜索
    isKeyNullActive: {
      type: Boolean,
      default: false,
    },
    // 是否在组件初始化的时候立即初始化选项，部分有联动的组件，立即初始化选项的化会报错
    immediate: {
      type: Boolean,
      default: true,
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false,
    },
    // 在多选的时候是否缩略后续选项
    collapseTags: {
      type: Boolean,
      default: false,
    },
    // 显示的label字段与字段之间的连接符
    flag: {
      type: String,
      default: '-',
    },
    // 选项显示的类型
    type: {
      type: String,
      default: 'stitching',
    },
    // 是否可以模糊搜索
    filterable: {
      type: Boolean,
      default: true,
    },
    // 是否可以远程搜索
    remote: {
      type: Boolean,
      default: true,
    },
    // 非可选项的字段提示
    unableInfo: {
      type: Array,
      default: () => ['名称', '原因'],
    },
    width: {
      type: [Number, String],
    },
    lazyLoad: {
      type: Boolean,
      default: false,
    },
    checkAll: {
      type: Boolean,
      default: false,
    },
    // 当watchKey生效时是否重置value
    autoClear: {
      type: Boolean,
      default: true,
    },
    // value是否为option对象
    isValueOption: {
      type: Boolean,
      default: false,
    },
    keyObj: Boolean,
    reserveKeyword: {
      type: Boolean,
      default: false,
    },
    // 当清空选项时，重新获取选项列表
    isNullRefresh: {
      type: Boolean,
      default: false,
    },
    // 自动选择符合规则的第一项
    autoSelect: {
      type: Boolean,
      default: false,
    },
    activeOptions: Array,
    unableOptions: Array,
  },
  data() {
    return {
      checkAllValue: false,
      isIndeterminate: false,
      activeList: [],
      unableList: [],
      loading: false,
      lazyLoading: false,
      isInit: false,
      page: 1,
      query: undefined,
      canLoad: true,
      objValue: '',
    };
  },
  computed: {
    selectedLabel() {
      return this.keyObj
        ? this.getLabel(this.objValue)
        : this.multiple
          ? this.$refs.select.selected.map((item) => {
            return { value: item.value, label: item.label };
          })
          : this.$refs.select.selectedLabel;
    },
    selected() {
      return this.$refs.select.selected;
    },
    isNull() {
      return !(this.activeOptions || this.activeList).concat(this.unableOptions || this.unableList)
        .length;
    },
  },
  watch: {
    // 初始化下拉选择组件的选项
    searchFunc: {
      handler() {
        if (!this.isInit && this.immediate) {
          this.isInit = true;
          this.optionSelect();
        }
      },
      immediate: true,
    },
    // 当监听的变量变化时且变量不为空时触发函数，因为多数有联动的搜索当前提为空时会报错
    // 可以设置isKeyNullActive为true，当监听的变量为空时也触发搜索
    watchKey() {
      if (this.autoClear) {
        if (this.keyObj) {
          this.objValue = '';
          this.$emit('input', {});
          this.change({});
        } else {
          this.$emit('input', this.value instanceof Array ? [] : '');
        }
      }
      if (this.watchKey || this.isKeyNullActive) {
        this.activeList = [];
        this.unableList = [];
        this.optionSelect();
      }
    },
    value() {
      if (!this.value && this.isNullRefresh) this.optionSelect();
      if (!this.checkAll) return;
      if (this.value.length === this.activeList.length) {
        this.checkAllValue = true;
        this.isIndeterminate = false;
      } else if (!this.value.length) {
        this.checkAllValue = false;
        this.isIndeterminate = false;
      } else {
        this.checkAllValue = false;
        this.isIndeterminate = true;
      }
    },
  },
  methods: {
    handleCheckAllChange(value) {
      if (value) {
        this.$emit(
          'input',
          this.activeList.map((item) => this.getValue(item)),
        );
      } else {
        this.$emit('input', []);
      }
    },
    optionSelect(query) {
      this.query = query;
      this.page = 1;
      this.canLoad = true;
      this.loading = true;
      return this.searchFunc(query, this.page)
        .then(async (response) => {
          this.page += 1;
          this.activeList.length = 0;
          this.unableList.length = 0;
          this.searchKey
            ? this.activeList.push(...response[this.searchKey])
            : this.activeList.push(...(response.activeList || response.list || response));
          this.unableList.push(...response.unableList);
          this.loading = false;
          if (
            this.autoSelect &&
            (this.keyObj ? !this.objValue : !this.value) &&
            this.activeList[0]
          ) {
            await this.$nextTick();
            const value = this.keyObj ? this.activeList[0] : this.activeList[0][this.optionValue];
            this.change(value);
            this.input(value);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    lazyLoadFunc() {
      if (this.lazyLoad && this.canLoad && !this.lazyLoading) {
        this.lazyLoading = true;
        this.searchFunc(this.query, this.page)
          .then((response) => {
            this.page += 1;
            const activeList = this.searchKey
              ? response[this.searchKey]
              : response.activeList || response.list || response;
            this.activeList.push(...activeList);
            this.unableList.push(...response.unableList);
            if (!(activeList.length + response.unableList.length)) {
              this.canLoad = false;
            }
            this.lazyLoading = false;
          })
          .catch(() => {
            this.lazyLoading = false;
          });
      }
    },
    input(value) {
      if (this.keyObj) this.objValue = value;
      this.$emit('input', value);
    },
    change(value) {
      this.$nextTick(() => {
        this.$emit('change', value, this.selectedLabel);
      });
    },
    getLabel(item) {
      if (this.optionLabel instanceof Array) {
        return this.optionLabel.map((label) => item[label]).join(this.flag);
      }
      return item[this.optionLabel];
    },
    getValue(item) {
      if (this.isValueOption) return item;
      if (this.optionValue instanceof Array) {
        return this.optionValue.map((value) => item[value]).join(this.flag);
      }
      return item[this.optionValue];
    },
    getFenceLabel(item) {
      if (this.fenceLabel instanceof Array) {
        return this.fenceLabel.map((label) => item[label]).join(this.flag);
      }
      return item[this.fenceLabel];
    },
    selectOptionClick(item) {
      this.$emit('selected', item);
    },
    handleClear() {
      if (this.keyObj && this.isNullRefresh) {
        this.optionSelect();
      }
      this.$emit('clear');
    },
    clearValue() {
      if (this.keyObj) this.$set(this, 'objValue', '');
    },
  },
};
</script>

<style lang="scss" scoped>
header,
article,
footer {
  margin: 10px;
}

.loading {
  font-size: 12px;
  color: rgb(144, 147, 153);
}

.checkall {
  margin-left: 20px;
  margin-top: 5px;
}
</style>
