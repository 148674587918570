import request from '@/utils/request'

// 获取首页列表
export function getRTList(query) {
  return request.post("v1/merchant/rtms/channelRtmsList", query);
}

// 改变单条计划状态
export function getRTStatus(query) {
  return request.post('v1/ks/updateStatus', query)
}

// 改变批量计划状态
export function getAllRTStatus(query) {
  return request.post('v1/ks/updateAllStatus', query)
}

// 获取单条计划预算
export function getDetail(query) {
  return request.get('v1/ks/getBudgetDetail', { params: query })
}
// 修改单条计划预算
export function modifyBudget(query) {
  return request.post('v1/ks/updateBudget', query)
}

// 获取单条计划出价
export function getPriceDetail(query) {
  return request.get('v1/ks/getBidDetail', { params: query })
}

// 修改单条计划出价
export function modifyPrice(query) {
  return request.post('v1/ks/updateBid', query)
}

// 获取单条计划定向
export function getCustomList(query) {
  return request.get('v1/ks/getTargetDetail', { params: query })
}

// 获取单条计划创意
export function getIdeaList(query) {
  return request.get('v1/ks/getCreativeDetail', { params: query })
}

// 复制单条计划
export function modifyksCopy(params) {
  return request.post('v1/copy/plan', params)
}

// 账号列表
export function getAccountList(query) {
  return request.get('v1/ks/accounts', { params: query })
}

// 推广计划列表
export function getCampaignList(query) {
  return request.get('v1/ks/campaignList', { params: query })
}

// 广告组列表
export function getGroupList(params) {
  return request.post('v1/ks/groupList', params)
}

// 获取单条计划时段
export function fetchScheduleDetail(query) {
  return request.get('v1/ks/schedule_detail', { params: query })
}

// 修改单条计划时段
export function modifySchedule(params) {
  return request.post('v1/ks/schedule_update', params)
}

// 修改批量计划时段
export function modifyBatchSchedule(params) {
  return request.post('v1/ks/batch_schedule_update', params)
}