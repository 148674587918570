<template>
  <div class="app-container">
    <article>
      <auto-table
        ref="autoTable"
        :column-list="columnList"
        :loading="data.loading"
        :loading-text="data.loadingMessage"
        :data="originList"
        :row-style="{ height: 0 }"
        :cell-style="{ padding: 0 }"
        :row-class-name="tableRowClassName"
      />
    </article>

    <notRepeat
      ref="notRepeat"
      :title="notRepeat.title"
      :show.sync="notRepeat.show"
      :info="notRepeat.info"
    />
  </div>
</template>

<script>
import NotRepeat from '@/components/notRepeat';
import AutoTable from '@/components/AutoTable';
import { getRTList, getRTStatus, getAllRTStatus } from '@/api/merchantData/rtmsChannel/ksApi';
import { getAccountList } from '@/api/ksPlanApi';
import { adminSelect } from '@/api/admin';
import { getDate } from '@/utils';
import { checkModelFieldShow, checkAdminWhite } from '@/utils/permission';
import { MessageBox } from 'element-ui';
import { messages } from '@/utils/uiTool';
import { mapState } from 'vuex';

const censusTypes = [
  { type: 'merge', name: '汇总数据' },
  { type: 'day', name: '按天显示' },
];

const showTypes = [
  { type: 'plan', name: '计划' },
  { type: 'game', name: '游戏' },
  { type: 'account', name: '账户' },
];

const operateTypes = [
  { type: 1, name: '开启' },
  { type: 2, name: '暂停' },
];

const adStatus = [
  { type: '', name: '全部' },
  { type: '有效', name: '有效' },
  { type: '已暂停', name: '已暂停' },
  { type: '组超预算', name: '组超预算' },
  { type: '不在投放时段', name: '不在投放时段' },
];

const defaultFilter = JSON.stringify({
  begin_date: getDate(0),
  end_date: getDate(0),
  charger_id: '',
  channel_depart: undefined,
  channels: [],
  games: [],
  account_id: undefined,
  status: '',
  census_type: 'merge',
  show_type: 'plan',
  operate_type: 1,
  plan_id: '',
  plan_name: undefined,
  sort: undefined,
  order: undefined,
  page: 1,
  limit: 50,
  warning: false,
  channel_type: 'ks',
  version: 2,
});

export default {
  name: 'RtmsTt',
  components: {
    NotRepeat,
    AutoTable,
  },
  data() {
    return {
      operateTypes,
      filter: JSON.parse(defaultFilter),
      display: false, // 更多显示列控制标志
      filterShow: true, // 控制搜索栏显示隐藏
      filterFlag: false, // 判断屏幕尺寸是否展示隐藏按钮和一些PC端的配置调整
      permission: '',
      autoFilter: [
        {
          type: 'date-picker',
          beginKey: 'begin_date',
          endKey: 'end_date',
          showOptions: true,
        },
        { type: 'game-filter-tem', key: 'games' },
        {
          type: 'channel-depart-tree-select',
          key: 'channel_depart',
          show: checkModelFieldShow('admin', 'user'),
        },
        {
          type: 'channel-tree-select',
          key: 'channels',
          watchkey: 'channel_depart',
        },
        {
          type: 'search-select',
          key: 'charger_id',
          watchKey: 'channel_depart',
          placeholder: '负责人',
          isKeyNullActive: true,
          optionLabel: ['id', 'name'],
          optionValue: 'id',
          show: checkModelFieldShow('admin', 'user'),
          searchFunc: this.getUsers,
        },
        {
          type: 'search-select',
          key: 'account_id',
          placeholder: '请选择账户',
          optionLabel: ['mobile_app_id', 'advertiser_id'],
          optionValue: 'advertiser_id',
          searchFunc: this.AccountSelect,
        },
        {
          type: 'select',
          key: 'status',
          placeholder: '状态',
          style: 'width:100px',
          valueKey: 'type',
          labelKey: 'name',
          optionList: adStatus,
          clearable: false,
        },
        {
          type: 'select',
          key: 'version',
          placeholder: '版本',
          style: 'width:100px',
          show: checkAdminWhite(),
          optionList: [{ value: 2, label: '版本v1.2' }],
        },
        {
          type: 'select',
          key: 'census_type',
          placeholder: '统计类型',
          style: 'width:100px',
          optionList: censusTypes,
          labelKey: 'name',
          valueKey: 'type',
          clearable: false,
        },
        {
          type: 'select',
          key: 'show_type',
          placeholder: '显示类型',
          style: 'width:85px',
          optionList: showTypes,
          onchange: this.showTypeChange,
          labelKey: 'name',
          valueKey: 'type',
          clearable: false,
        },
        {
          type: 'input-number',
          key: 'plan_id',
          placeholder: '计划ID',
          width: 150,
          show: (form) => form.show_type === 'plan',
          verify: [
            {
              rule: (val) => String(val).length <= 11,
              tip: '计划ID长度不能大于11位',
            },
          ],
        },
        {
          type: 'input',
          key: 'plan_name',
          placeholder: '计划名称',
          width: 240,
          show: (form) => form.show_type === 'plan',
        },
      ],
      buttonList: [
        {
          type: () => (this.display ? '' : 'primary'),
          label: '更多显示列',
          onclick: () => {
            this.display = !this.display;
          },
        },
      ],
      // 更多显示列展示列字段
      selectColums: {
        // 创角相关字段
        cloumnsCreateRole: {
          all: false,
          create_role_num: false,
          create_role_rate: false,
          create_role_success_num: false,
          create_role_success_rate: false,
        },
      },
      data: {
        loading: false,
        loadingMessage: '正在加载...',
        total: 0,
        list: [],
      },
      notRepeat: {
        show: false,
        title: undefined,
        info: undefined,
      },
      edit: {
        show: false,
        title: undefined,
      },
      price: {
        show: false,
        title: undefined,
      },
      copy: {
        show: false,
        title: undefined,
      },
      custom: {
        show: false,
        title: undefined,
      },
      idea: {
        show: false,
        title: undefined,
      },
      schedule: {
        show: false,
        title: undefined,
      },
      batchSchedule: {
        show: false,
        title: undefined,
      },
      multipleSelection: [],
    };
  },
  computed: {
    ...mapState('app', {
      dataColumns: (state) => state.dataColumns,
      originList: (state) => state.originList
    }),
    columnList() {
      let list = this.dataColumns.map(item => {
        return {
          prop: item,
          label: item,
          width: 120,
        }
      })
      list.push({
        prop: '',
        label: '操作',
        type: 'button',
        name: 'button',
        onclick: (row) => this.repeatShow(row),
      })
      return list;
    }
  },
  created() {
    this.permission = this.$route.meta.permission;
  },
  mounted() {
    this.getList();
    document.title = '远观大数据';
    const _this = this;
    window.addEventListener('orientationchange', _this.filpDisplay);
    this.filterFlag = document.body.clientWidth <= 1000;
  },
  destroyed() {
    const _this = this;
    window.removeEventListener('orientationchange', _this.filpDisplay);
  },
  methods: {
    // 搜索栏隐藏功能
    changeFilterShow() {
      this.filterShow = !this.filterShow;
      this.$refs.autoTable.setScrollBar(document.body.clientHeight * 0.7);
    },
    // 判断移动端屏幕是否翻转
    filpDisplay() {
      setTimeout(() => {
        this.filterFlag = document.body.clientWidth <= 1000;
        this.$refs.autoTable.setScrollBar(document.body.clientHeight * 0.7);
      }, 300);
    },
    // 全部展示、隐藏
    selectAllColumn(select) {
      for (var columnAboveKey in this.selectColums) {
        var vueThis = this;
        this.selectAboveColum(select, columnAboveKey, vueThis);
      }
      this.$refs.autoTable.doLayout();
    },
    // 单种类全部展示、隐藏
    selectAboveColum(select, columnAboveKey, vueThis) {
      if (typeof vueThis === 'undefined') {
        vueThis = this;
      }
      for (var columnKey in vueThis.selectColums[columnAboveKey]) {
        vueThis.selectColums[columnAboveKey][columnKey] = select;
      }
      this.$refs.autoTable.doLayout();
    },
    // 单列展示、隐藏
    selectSingleColum(modelObj, columnName) {
      modelObj[columnName] = !modelObj[columnName];
      this.$refs.autoTable.doLayout();
    },
    getPrecent(row, key) {
      if (!Number(row[key])) return row[key];
      return row[key] + '%';
    },
    showOperation(row, index) {
      return !!index;
    },
    showWarningData() {
      this.filter.warning = !this.filter.warning;
      this.filter.page = 1;
      this.$refs.autoFilter.form.warning = this.filter.warning;
      this.getList();
    },
    tableRowClassName({ row }) {
      return row.waring_sign === true ? 'bad-plan-row' : '';
    },
    getUsers(query) {
      return adminSelect({
        name: query,
        depart_ids: this.$refs.autoFilter.form.channel_depart,
        id: parseInt(query) ? parseInt(query) : undefined,
      });
    },
    getList() {
      this.data.loading = true;
      getRTList(this.filter)
        .then((response) => {
          this.data.total = response.total;
          this.data.list = response.list;
          this.data.loading = false;
        })
        .catch(() => {
          this.data.loading = false;
        });
    },
    AccountSelect(query) {
      return getAccountList({
        name: query,
        id: parseInt(query) ? parseInt(query) : undefined,
      });
    },
    showTypeChange() {
      const form = this.$refs.autoFilter.form;
      this.$refs.autoFilter.$set(form, 'plan_id', '');
      this.$refs.autoFilter.$set(form, 'plan_name', '');
    },
    handleFilter() {
      this.filter.page = 1;
      this.$refs.autoTable.clearSort();
      this.getList();
    },
    handleReset() {
      this.$refs.autoFilter.form = JSON.parse(defaultFilter);
    },
    sortChange(column) {
      this.filter.sort = column.prop;
      this.filter.order = column.order;
      this.$refs.autoFilter.form.sort = column.prop;
      this.$refs.autoFilter.form.order = column.order;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map((item) => {
        return {
          account_id: item.account_id,
          ad_id: item.ad_id,
        };
      });
    },
    handleSelectAble(row, index) {
      if (this.filter.show_type === 'plan') {
        return index !== 0;
      }
      return false;
    },
    handlePaused(account_id, ad_id, type) {
      const c_name = type === 2 ? '暂停' : '开启';
      MessageBox.confirm(`确认${c_name}`, '警告', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.data.loading = true;
        this.data.loadingMessage = '正在操作...';
        getRTStatus({
          account_id: account_id,
          ad_id: ad_id,
          pause: type,
        })
          .then(() => {
            this.data.loading = false;
            this.getList();
          })
          .catch(() => {
            this.data.loading = false;
          });
      });
    },
    handleOprate() {
      if (!this.multipleSelection.length) {
        return messages('请先选择要操作的计划！', 'error');
      }
      const plan_result = this.multipleSelection.map((element) => {
        return { account_id: element.account_id, ad_id: element.ad_id };
      });
      let c_name = '';
      if (this.filter.operate_type === 2) {
        c_name = '暂停';
      } else if (this.filter.operate_type === 1) {
        c_name = '开启';
      }
      if (this.filter.operate_type === 1 || this.filter.operate_type === 2) {
        MessageBox.confirm(`确认${c_name}`, '警告', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.data.loading = true;
          this.data.loadingMessage = '正在操作...';
          getAllRTStatus({
            plans: plan_result,
            pause: this.filter.operate_type,
          })
            .then(() => {
              this.data.loading = false;
              this.getList();
            })
            .catch(() => {
              this.data.loading = false;
            });
        });
      }
    },
    repeatShow() {
      let row = {
        account_id: "9850542",
        ad_id: "254501775",
        ad_status: "有效",
        advertiser_id: "9850542",
        arpu: "118.0",
        begin_date: "2021-06-01",
        budget: "0.00",
        budget_rmb: "0.00",
        campaign_id: "254501775",
        channel_balance: "44431.36",
        channel_id: "5ecc844c7638c3118c267c52",
        channel_user_id: 2235881653,
        channel_user_name: "换姬战三国资质",
        click: "267795",
        click_percent: "3137.24",
        click_unit_price: "0.04",
        cost: "13230.63",
        cost_rmb: "10459.00",
        created_roles: "0",
        created_roles_percent: "0.0",
        created_succeed_roles: "287",
        created_succeed_roles_percent: "95.99",
        day1_return_rate: "15.80",
        ecpm: "1225.28",
        ltv_1: "5.53",
        ltv_2: "5.53",
        ltv_3: "5.53",
        paid_unit_price: 747.07,
        pay_cost: "747.07",
        pay_money_total: "1652",
        pay_number_total: 14,
        pay_percent: "4.68",
        plan_id: "60b486cf240df5414d00264a",
        plan_name: "2535188-云锐542-然-三国-roi-程-人物更换",
        register: 299,
        register_cost: "34.98",
        register_diff: 285,
        register_percent: "0.11",
        show: "8536",
      }
      this.notRepeat.show = true;
      this.notRepeat.title = '排重数据';
      row.begin_date = this.filter.begin_date;
      row.end_date = this.filter.end_date;
      this.notRepeat.info = row;
    },
    handleEdit(row) {
      this.edit.show = true;
      this.edit.title = '修改预算';
      this.$refs.edit.setId(row.account_id, row.ad_id);
    },
    updateEditShow(val) {
      this.edit.show = val;
    },
    handlePriceEdit(row) {
      this.price.show = true;
      this.price.title = '修改出价';
      this.$refs.price.setId(row.account_id, row.ad_id);
    },
    updatePriceShow(val) {
      this.price.show = val;
    },
    handleCopy(row) {
      this.copy.show = true;
      this.copy.title = '计划复制';
      this.$refs.copy.setId(row.plan_name, row.account_id, row.ad_id);
    },
    updateCopyShow(val) {
      this.copy.show = val;
    },
    handleCustom(row) {
      this.custom.show = true;
      this.custom.title = '计划定向';
      this.$refs.custom.setId(row.account_id, row.ad_id);
    },
    updateCustomShow(val) {
      this.custom.show = val;
    },
    handleIdea(row) {
      this.idea.show = true;
      this.idea.title = '计划创意';
      this.$refs.idea.setId(row.account_id, row.ad_id);
    },
    handleSchedule(row) {
      this.schedule.show = true;
      this.schedule.title = '修改投放时段';
      this.$refs.schedule.setId(row.account_id, row.ad_id);
    },
    updateScheduleShow(val) {
      this.schedule.show = val;
    },
    handleBatchSchedule() {
      if (!this.multipleSelection.length) {
        return messages('请先选取计划！', 'error');
      }
      this.batchSchedule.show = true;
      this.batchSchedule.title = '批量修改投放时段';
      this.$refs.batchSchedule.setArr(this.multipleSelection);
    },
    updateBatchScheduleShow(val) {
      this.batchSchedule.show = val;
    },
  },
};
</script>

<style lang="scss">
.column_select_table {
  .el-button.el-button--mini.column_button {
    padding: 7px 10px;
  }
  .column_button {
    width: 100px;
    display: inline-block;
    zoom: 1;
    *display: inline;
    vertical-align: baseline;
    margin: 0 2px;
    outline: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font: 10px/100% Arial, Helvetica, sans-serif;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    border-radius: 0.5em;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }
  .white {
    color: #606060;
    border: solid 1px #b7b7b7;
    background: #fff;
    background: -moz-linear-gradient(top, #fff, #ededed);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ededed');
  }
  .blue {
    color: #d9eef7;
    border: solid 1px #0076a3;
    background: #0095cd;
    background: -moz-linear-gradient(top, #00adee, #0078a5);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00adee', endColorstr='#0078a5');
  }
  .blue:active {
    color: #d9eef7;
    border: solid 1px #0076a3;
    background: #0095cd;
    background: -moz-linear-gradient(top, #00adee, #0078a5);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00adee', endColorstr='#0078a5');
  }

  .blue:hover {
    color: #e7ecee;
    border: solid 1px #011d27;
    background: #65c6ec;
    background: -moz-linear-gradient(top, #005677, #003c52);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00adee', endColorstr='#005677');
  }

  .blue:focus {
    color: #e7ecee;
    border: solid 1px #011d27;
    background: #65c6ec;
    background: -moz-linear-gradient(top, #005677, #003c52);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00adee', endColorstr='#005677');
  }
}
.bad-plan-row {
  color: red !important;
}
</style>

<style lang="scss" scoped>
header,
article {
  margin: 10px;
}
</style>
