<template>
  <div v-loading="loading" :element-loading-text="loadingText">
    <el-table
      ref="tableRef"
      :data="data"
      :max-height="fixHeight || height"
      :height="fixHeight || height"
      :style="`${fitContent ? 'width:fit-content;' : ''}`"
      :row-style="rowStyle"
      :cell-style="cellStyle"
      highlight-current-row
      :border="border"
      :fit="fit"
      :default-sort="defaultSort"
      :show-summary="showSummary"
      :summary-method="summaryMethod"
      @sort-change="handleSortChange"
      @selection-change="handleSelectionChange"
    >
      <!-- :row-class-name="rowClassName" -->
      <!-- 复选框start -->
      <el-table-column
        v-if="selectable"
        type="selection"
        width="39"
        :selectable="selectableFunc"
      ></el-table-column>
      <!-- 复选框end -->
      <!-- 单元格start -->
      <template v-for="(item, index) of columnList">
        <!-- 展开栏start -->
        <template v-if="item.expand">
          <el-table-column
            v-if="getParameter(item, undefined, 'show', true)"
            :key="'columnList_' + index"
            :label="item.label"
            :width="getParameter(item, undefined, 'width', 100)"
            type="expand"
          >
            <template slot-scope="props">
              <!-- 表单类型start -->
              <template v-if="item.type === 'form'">
                <el-form v-if="getValue(item, props)">
                  <el-form-item
                    v-for="(formItem, formIndex) in getValue(item, props)"
                    :key="'form_' + formIndex"
                    :label="
                      (item.form.label ? item.form.label : 'info') +
                      '_' +
                      (formIndex + 1)
                    "
                    label-width="100px"
                  >
                    <span>{{
                      getParameter(item.form, formItem, "value", "")
                    }}</span>
                  </el-form-item>
                </el-form>
                <span v-else>{{ "无数据" }}</span>
              </template>
              <!-- 表单类型end -->
              <!-- 表格类型start -->
              <template v-if="item.type === 'table'">
                <el-table
                  :data="getValue(item, props)"
                  border
                  style="width: 80%"
                >
                  <template v-for="(tableItem, tableIndex) of item.talbeList">
                    <el-table-column
                      v-if="getParameter(tableItem, undefined, 'show', true)"
                      :key="'table_' + tableIndex"
                      :label="tableItem.label"
                      :align="tableItem.align || 'center'"
                      :width="getParameter(tableItem, undefined, 'width', 100)"
                      :show-overflow-tooltip="
                        tableItem['show-overflow-tooltip']
                      "
                      :fixed="getParameter(tableItem, undefined, 'fixed')"
                      :prop="tableItem.prop"
                    >
                      <template slot-scope="propsSlot">
                        <!-- 文字start -->
                        <span
                          v-if="!tableItem.type"
                          :style="
                            getParameter(tableItem, propsSlot.row, 'style', '')
                          "
                          @click="
                            tableItem.onclick
                              ? tableItem.onclick(propsSlot.row)
                              : () => {}
                          "
                        >
                          {{ getValue(tableItem, propsSlot) }}
                        </span>
                        <!-- 文字end -->
                        <!-- 按钮start v-if="tableItem.type === 'operation'" -->
                        <template>
                          <template
                            v-for="(
                              tableButton, tableButtonIndex
                            ) of tableItem.buttonList"
                          >
                            <!-- v-if="
                                getButtonShow(
                                  tableButton,
                                  propsSlot.row,
                                  propsSlot.$index
                                )
                              " -->
                            <!-- <el-button
                              :key="`${tableIndex}button${tableButtonIndex}`"
                              :class="
                                getParameter(
                                  tableButton,
                                  propsSlot.row,
                                  'class'
                                )
                              "
                              :style="
                                getParameter(
                                  tableButton,
                                  propsSlot.row,
                                  'style'
                                )
                              "
                              :disabled="
                                getParameter(
                                  tableButton,
                                  propsSlot.row,
                                  'disabled',
                                  false
                                )
                              "
                              :type="
                                getParameter(tableButton, propsSlot.row, 'type')
                              "
                              :size="tableButton.size || 'mini'"
                              :icon="tableButton.icon"
                              @click="
                                tableButton.onclick(propsSlot.row, $event)
                              "
                            > -->
                            <el-button
                              :key="`${tableIndex}button${tableButtonIndex}`"
                            >
                              {{ "anniu" }}
                            </el-button>
                          </template>
                        </template>
                        <!-- 按钮end -->
                        <!-- A标签start -->
                        <template v-if="tableItem.type === 'href'">
                          <a
                            :href="getValue(tableItem, propsSlot, 'href')"
                            :target="tableItem.target"
                            :style="
                              getParameter(
                                tableItem,
                                propsSlot.row,
                                'style',
                                ''
                              )
                            "
                          >
                            {{ getValue(tableItem, propsSlot, "href") }}
                          </a>
                        </template>
                        <!-- A标签end -->
                        <!-- Tag标签start -->
                        <template v-if="tableItem.type === 'tag'">
                          <el-tag
                            :type="
                              getParameter(
                                tableItem,
                                propsSlot.row,
                                'typeKind',
                                'info'
                              )
                            "
                          >
                            {{ getValue(tableItem, propsSlot) }}
                          </el-tag>
                        </template>
                        <!-- Tag标签end -->
                      </template>
                    </el-table-column>
                  </template>
                </el-table>
              </template>
            </template>
            <!-- 表格类型end -->
          </el-table-column>
        </template>
        <!-- 展开栏end -->
        <template v-else>
          <el-table-column
            v-if="getParameter(item, undefined, 'show', true)"
            :key="'columnList_' + index"
            :label="getParameter(item, undefined, 'label', '')"
            :align="item.align || 'center'"
            :width="getParameter(item, undefined, 'width', 100)"
            :show-overflow-tooltip="item['show-overflow-tooltip']"
            :fixed="getParameter(item, undefined, 'fixed')"
            :prop="item.prop"
            :sortable="getParameter(item, undefined, 'sortable', false)"
            :sort-method="item['sort-method']"
            :sort-by="item['sort-by']"
          >
            <template slot-scope="slot">
              <!-- 文字start -->
              <span
                v-if="!item.type || !getParameter(item, slot.row, 'type', '')"
                :style="getParameter(item, slot.row, 'style', '')"
                @click="item.onclick ? item.onclick(slot.row) : () => {}"
              >
                {{ getValue(item, slot) }}
              </span>
              <el-button v-else @click="item.onclick(slot.row, $event)">
                {{ "详情" }}
              </el-button>
              <!-- 文字end -->
              <!-- 带popover文字start -->
              <!-- <el-popover
                v-if="getParameter(item, slot.row, 'type', '') === 'popover'"
                placement="top-start"
                :title="getParameter(item.popover, slot.row, 'title', '提示')"
                :width="getParameter(item.popover, slot.row, 'width', '200')"
                trigger="hover"
              >
                <p :style="getParameter(item.popover, slot.row, 'style', '')">
                  {{ getParameter(item.popover, slot.row, "content", "") }}
                </p>
                <span
                  slot="reference"
                  :style="getParameter(item, slot.row, 'style', '')"
                  @click="item.onclick ? item.onclick(slot.row) : () => {}"
                >
                  {{ getValue(item, slot) }}
                </span>
              </el-popover> -->
              <!-- 带popover文字end -->
              <!-- 按钮start -->
              <!-- <template>
                <template v-for="(button, buttonIndex) of item.buttonList">
                   v-if="getButtonShow(button, slot.row, slot.$index)" -->
              <!-- <el-button
                    :key="`${index}button${buttonIndex}`"
                    :class="getParameter(button, slot.row, 'class')"
                    :style="getParameter(button, slot.row, 'style')"
                    :disabled="
                      getParameter(button, slot.row, 'disabled', false)
                    "
                    :type="getParameter(button, slot.row, 'type')"
                    :size="button.size || 'mini'"
                    :icon="button.icon"
                    @click="button.onclick(slot.row, $event)"
                  > -->
              <!-- <el-button :key="`${index}button${buttonIndex}`">
                    {{ "按钮" }}
                  </el-button>
                </template>
              </template> -->

              <!-- 按钮end -->
              <!-- A标签start -->
              <!-- <template v-if="item.type === 'href'">
                <a
                  :href="
                    getValue(item, slot, 'href') === '-'
                      ? 'javascript:void(0);'
                      : getValue(item, slot, 'href')
                  "
                  :target="item.target"
                  :style="getParameter(item, slot.row, 'style', '')"
                >
                  {{ getValue(item, slot, "href") }}
                </a>
              </template> -->
              <!-- A标签end -->
              <!-- Tag标签start -->
              <!-- <template v-if="item.type === 'tag'">
                <el-tag
                  :type="getParameter(item, slot.row, 'typeKind', 'info')"
                >
                  {{ getValue(item, slot) }}
                </el-tag>
              </template> -->
              <!-- Tag标签end -->
            </template>
          </el-table-column>
        </template>
      </template>
      <!-- 单元格end -->
    </el-table>
    <!-- 分页栏start -->
    <pagination
      v-if="pagination"
      v-show="+total > 0"
      style="overflow-x: auto"
      :total="total"
      :page.sync="filterPage"
      :limit.sync="filterLimit"
      :layout="layout"
      :page-sizes="pageSizes"
      @pagination="paginationChange"
    />
    <!-- 分页栏end -->
  </div>
</template>

<script>
import Pagination from './Pagination';

export default {
  components: {
    Pagination,
  },
  props: {
    // 是否展示加载效果
    loading: {
      type: Boolean,
      default: false,
    },
    // 加载效果里的文字内容
    loadingText: {
      type: String,
      default: '正在加载...',
    },
    // 表格内的数据
    data: {
      type: Array,
      default: () => [],
    },
    // 数据总数量
    total: {
      type: Number,
      default: 0,
    },
    // 页码数
    page: {
      type: Number,
      default: 1,
    },
    // 单页展示数量
    limit: {
      type: Number,
      default: 50,
    },
    // 分页栏布局
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper',
    },
    // 分页栏页内大小种类
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50];
      },
    },
    // 表格是否自适应表格
    autoHeight: {
      type: Boolean,
      default: true,
    },
    // 表格高度
    fixHeight: { type: Number, default: undefined },
    // 是否自适应表格最大宽度
    fitContent: {
      type: Boolean,
      default: false,
    },
    // 行的 className 的回调方法
    // rowClassName: String,
    // 行的style的回调方法
    rowStyle: {
      type: Object,
      default() {
        return {};
      },
    },
    // 单元格的 style 的回调方法
    cellStyle: {
      type: Object,
      default() {
        return {};
      },
    },
    // 是否显示合计行
    showSummary: {
      type: Boolean,
      default: false,
    },
    // 自定义合计行计算方法
    summaryMethod: {
      type: Function,
      default: () => {
        return () => { };
      },
    },
    // 是否出现复选框
    selectable: {
      type: Boolean,
      default: false,
    },
    // 复选框函数
    selectableFunc: { type: Function, default: () => { } },
    // 复选框钩子数据
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    // 列头内容
    columnList: {
      type: Array,
      default: () => [],
    },
    // 默认的排序列的prop和顺序
    defaultSort: {
      type: Object,
      default: () => { },
    },
    // 是否带有纵向边框
    border: {
      type: Boolean,
      default: true,
    },
    // 列的宽度是否自撑开
    fit: {
      type: Boolean,
      default: false,
    },
    // 是否显示分页栏
    pagination: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filterPage: 1,
      filterLimit: 50,
      height: 680,
    };
  },
  watch: {
    page() {
      this.filterPage = this.page;
    },
    limit() {
      this.filterLimit = this.limit;
    },
    loading() {
      if (!this.loading) {
        this.doLayout();
      }
    },
  },
  mounted() {
    this.filterPage = this.page;
    this.filterLimit = this.limit;
    this.setSummary();
    // 浏览器缩放，更新ScrollBar位置
    this.setScrollBar();
    if (document.body.clientWidth > 1000) {
      if (this.autoHeight) window.addEventListener('resize', this.setScrollBar);
    }
    if (this.showSummary) {
      setTimeout(() => {
        const body = document.getElementsByClassName('el-table')[0];
        body.style['max-height'] = body.offsetHeight + 37 + 'px';
        body.style.height = body.offsetHeight + 37 + 'px';
      }, 2000);
    }
  },
  destroyed() {
    if (document.body.clientWidth > 1000) {
      if (this.autoHeight) window.removeEventListener('resize', this.setScrollBar);
    }
  },
  methods: {
    // 加载时刷新表格样式
    doLayout() {
      this.$nextTick(() => {
        window.setTimeout(() => {
          this.$refs.tableRef.doLayout();
        }, 100);
      });
    },
    // 自适应展示高度监听事件
    setScrollBar(flag) {
      if (typeof flag === 'number') {
        this.$set(this, 'height', flag);
      } else {
        this.$nextTick(function () {
          if (this.display) {
            this.$set(this, 'height', document.body.clientHeight - 410);
          } else {
            this.$set(this, 'height', document.body.clientHeight - 245);
          }
        });
      }
    },
    // 设置合计行到首部
    setSummary() {
      if (!this.showSummary) return;
      const footer = document.getElementsByClassName('el-table__footer-wrapper');
      const body = document.getElementsByClassName('el-table__body-wrapper');
      for (let i = 0; i < footer.length; i++) {
        body[i].parentNode.insertBefore(footer[i], body[i]);
      }
    },
    // 获取外部配置参数
    getParameter(item, row, key, defaultValue) {
      try {
        if (item[key] instanceof Function) return item[key](row);
        return item[key] === undefined ? defaultValue : item[key];
      } catch (err) {
        console.error(`参数‘${key}’配置有问题！`);
      }
    },
    // 单元格内容
    getValue(item, slot, key) {
      if (slot.row) {
        return item.formatter
          ? item.formatter(slot.row, slot.$index)
          : slot.row[item[key] || item.prop];
      } else {
        return item.formatter ? item.formatter(slot) : slot[item[key] || item.value];
      }
    },
    // 单元格按钮是否展示
    getButtonShow(button, row, index) {
      if (button.show instanceof Function) {
        return button.show(row, index);
      }
      return button.show === undefined ? true : button.show;
    },
    // 复选框钩子
    handleSelectionChange(val) {
      this.$emit('input', val);
      this.$emit('selection-change', val);
    },
    // 本地手动排序
    sort(prop, sort) {
      this.$refs.tableRef.sort(prop, sort);
    },
    // 本地手动清空排序条件，同时表格恢复未排序状态
    clearSort() {
      this.$refs.tableRef.clearSort();
    },
    // 排序条件变化时的触发事件
    handleSortChange({ column, prop, order }) {
      this.$emit('sort-change', { column, prop, order });
    },
    // 分页变化钩子
    paginationChange(obj) {
      this.$emit('update:page', obj.page);
      this.$emit('update:limit', obj.limit);
      this.$emit('pagination');
    },
  },
};
</script>

<style lang="scss" scoped>
header,
article,
footer {
  margin: 10px;
}
</style>
<style lang="scss">
.el-table__empty-text {
  text-align: left;
}
.el-tooltip__popper {
  max-width: 800px;
}
</style>
