import request from "@/utils/request";

// KuaiShou——Get Account List
export function getAccountList(query) {
  return request.get("v1/ks/accounts", { params: query });
}

// KuaiShou——Get Tag List
export function getTagList(query) {
  return request.get("v1/ks/select_tag", { params: query });
}

// KuaiShou——Get Campaign List
export function getCampaignList(query) {
  return request.get("v1/ks/campaignList", { params: query });
}

// KuaiShou——Get Ad Group List
export function getGroupList(params) {
  return request.post("v1/ks/groupList", params);
}

// KuaiShou——Get material List
export function getMaterialList(params) {
  return request.post("v1/ks/creativeList", params);
}

// KuaiShou——Add/Edit View Save Operation
export function saveInfo(params) {
  return request.post("v1/ks/modifyMorePlan", params);
}

// KuaiShou——Get Material Lib
export function getMaterialLib(params) {
  return request.post("v1/ks/fileSources", params);
}

// 素材自带封面库
export function getCoverLib(query) {
  return request.post("v1/yuewan/getCoverDetailById", query);
}

// 快手封面库列表
export function getCoverList(query) {
  return request.post("v1/cover_library/list", query);
}

// 封面类型和标签下拉表
export function getTagCover() {
  return request.get("v1/cover_tag/tag_list");
}

// 修改快手计划
export function modifyPlan(query) {
  return request.post("v1/ks/batchUnit", query);
}

// 获取创意
export function getIdea(params) {
  return request.get("v1/ks/getIdea", { params: params });
}

// 保存创意
export function saveIdea(query) {
  return request.post("v1/ks/creativeModify", query);
}

// 获取程序化创意
export function getProgramCreativeList(query) {
  return request.post("v1/ks/programCreativeList", query);
}

// 批量修改后缀
export function updateSuffix(query) {
  return request.post("v1/ks/updateSuffix", query);
}
