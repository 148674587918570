import request from '@/utils/request';

// 合同游戏下拉表
export function fetchContractGame(query) {
  return request.get('v1/common/gameTags', { params: query });
}
// 通过计划ID获取合同游戏
export function fetchPlanGame(query) {
  return request.post('v1/merchant/rtms/getPlanTagId', query);
}
// 查询排重率数据
export function fetchRemovalRate(query) {
  return request.post('v1/merchant/rtms/getDeviceInfo', query);
}
