<template>
  <el-dialog
    :direction="direction"
    :size="size"
    :title="title"
    :visible.sync="show"
    :before-close="cancel"
    @open="open"
  >
    <el-form
      ref="form"
      v-loading="form.loading"
      :element-loading-text="form.loadingMessage"
      :rules="form.rules"
      :model="form.data"
      style="margin-left: 20px; height: 90%; overflow-y: auto"
      label-width="90px"
    >
      <el-form-item label="计划名称:">
        <span>{{ planName }}</span>
      </el-form-item>

      <el-form-item label="排重时长:">
        <el-select v-model="form.data.time_range" style="width: 50%">
          <el-option
            v-for="item in TIME"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="排重池:" prop="game_tag_id">
        <search-select
          ref="contractGame"
          v-model="form.data.game_tag_id"
          :search-func="(query) => contractGameSelect(query, form.data.os)"
          :option-label="[`id`, `tag_name`]"
          option-value="id"
          placeholder="请选择合同游戏"
          style="width: 50%"
          :multiple="true"
          :collapse-tags="true"
        />
      </el-form-item>

      <div class="display-line">
        <el-button :loading="form.loading" type="primary" @click="getList"
          >查询</el-button
        >
        &nbsp;
        <span>请点击查询展示结果</span>
      </div>

      <div
        v-if="tableHeader.length !== 0 && tableHeader.length !== 1"
        class="display-line"
      >
        <ve-line :data="chartData" :settings="chartSettings" />
      </div>

      <div v-if="tableHeader.length !== 0" class="display-line">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="name" label="日期" width="80" align="center">
          </el-table-column>
          <el-table-column
            v-for="(item, index) in tableHeader"
            :key="index"
            :prop="'value' + index"
            :label="item"
            align="center"
            :width="tableHeader.length === 1 ? 260 : 90"
          >
          </el-table-column>
        </el-table>
      </div>

      <el-form-item label="创角数据:"> </el-form-item>
      <div class="create-role-data">
        <el-table :data="roleTableData" border style="width: 401px">
          <el-table-column
            v-for="item in ROLE_COLUMN"
            :key="item.name"
            :prop="item.name"
            :label="item.label"
            align="center"
            width="100"
          >
          </el-table-column>
        </el-table>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { fetchContractGame, fetchPlanGame, fetchRemovalRate } from '@/api/public/data';
import SearchSelect from './searchSelect'

const TIME = [
  { name: '3天', id: 1 },
  { name: '1个月', id: 2 },
  { name: '3个月', id: 3 },
  { name: '全部', id: 4 },
];

const ROLE_COLUMN = [
  { name: 'created_roles', label: '创角数' },
  { name: 'created_roles_percent', label: '创角率' },
  { name: 'created_succeed_roles', label: '创角成功数' },
  { name: 'created_succeed_roles_percent', label: '创角成功率' },
];

const defaultData = JSON.stringify({
  start_time: undefined,
  end_time: undefined,
  plan_id: undefined,
  channel_id: undefined,
  time_range: 3, // 时间默认选中 三个月。必填
  game_tag_id: [], // 合同游戏，默认选中当前游戏所在的合同游戏。可多选。必填。
  os: undefined,
});

export default {
  name: 'NotRepeat',
  props: {
    title: { type: String, default: '' },
    show: { type: Boolean, default: false },
    info: { type: Object, default: undefined },
  },
  data() {
    this.chartSettings = {
      yAxisType: ['0%'],
    };
    return {
      TIME, // 排重时长下拉表
      ROLE_COLUMN, // 创角数据表头
      direction: 'rtl',
      size: '40%', // 界面宽度
      planName: undefined, // 获取的计划名称
      // 可视化数据
      chartData: {
        columns: ['date', '非重率'],
        rows: [],
      },
      form: {
        loading: false,
        loadingMessage: '正在加载...',
        data: JSON.parse(defaultData),
        rules: {
          game_tag_id: [{ required: true, trigger: 'blur', message: '合同游戏不能为空' }],
        },
      },
      tableHeader: [], // 排重数据表头
      tableData: [], // 排重数据
      roleTableData: [], // 创角数据
    };
  },
  components: {
    SearchSelect
  },
  methods: {
    // 合同游戏下拉表
    contractGameSelect(query, os) {
      const params = {
        name: query,
        page: 1,
        limit: 100,
        os: os,
      };
      if (parseInt(query)) {
        params.id = +query;
        delete params.name;
      }
      return fetchContractGame(params);
    },
    setFormData(data) {
      this.planName = undefined;
      this.chartData.rows = [];
      this.tableHeader = [];
      this.tableData = [];
      this.roleTableData = [];
      this.form.data = data;
    },
    open() {
      this.planName = this.info.plan_name;
      this.form.data.start_time = this.info.begin_date + ' 00:00:00';
      this.form.data.end_time = this.info.end_date + ' 23:59:59';
      this.form.data.plan_id = this.info.plan_id;
      this.form.data.channel_id = this.info.channel_id;
      // 创角数据
      const data = {};
      data.created_roles = this.info.created_roles || 0;
      data.created_roles_percent = (this.info.created_roles_percent || 0) + '%';
      data.created_succeed_roles = this.info.created_succeed_roles || 0;
      data.created_succeed_roles_percent = (this.info.created_succeed_roles_percent || 0) + '%';
      this.roleTableData = [data];

      this.form.loading = true;
      fetchPlanGame({ plan_id: this.info.plan_id })
        .then((response) => {
          this.form.data.game_tag_id.push(response.tag_id);
          this.form.data.os = response.os;
          this.$refs.contractGame.optionSelect();
          this.form.loading = false;
        })
        .catch(() => {
          this.form.loading = false;
        });
    },
    getList() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.loading = true;
          fetchRemovalRate(this.form.data)
            .then((response) => {
              this.tableHeader = [];
              this.chartData.rows = [];
              const rate = { name: '非重率' };
              const num = { name: '非重数' };
              for (const index in response) {
                this.tableHeader.push(response[index].showtime_tag);
                rate['value' + index] =
                  response[index].rt_device_per.toString().split('.')[0] + '%';
                num['value' + index] = response[index].rt_device_un_num;
                this.chartData.rows.push({
                  date: response[index].showtime_tag,
                  非重率: +response[index].rt_device_per.split('.')[0] / 100,
                });
              }
              this.tableData = [rate, num];
              this.form.loading = false;
            })
            .catch(() => {
              this.form.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit('update:show', false);
      this.setFormData(JSON.parse(defaultData));
    },
  },
};
</script>
<style lang="scss" scoped>
.display-line {
  margin: 10px 0;
}
.create-role-data {
  margin-left: 50px;
}
</style>
